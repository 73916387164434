@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 9%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
        --border: 0 0% 83.1%;
        --input: 0 0% 83.1%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
        --primary: 0 0% 45.1%;
        --primary-foreground: 0 0% 100%;
        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;
        --accent: 0 0% 32.2%;
        --accent-foreground: 0 0% 100%;
        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;
        --ring: 0 0% 25.1%;
        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply font-sans antialiased bg-background text-foreground;
    }
}

@layer components {
    .app-bg {
        background-image: url(@images/stacked-wave-2.svg);
        background-repeat: no-repeat;
        background-position: center top;
        transform: rotate(180deg);
        @apply w-full h-[381px] 2xl:bg-cover 2xl:top-20;
    }
}

@layer utilities {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    /* Customize scrollbar on Desktop only */
    @media (pointer: fine) {
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 10px 10px #e5e5e5;
        }

        ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 10px 10px #a3a3a3;
            border-radius: 40px;
        }

        ::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px #737373;
        }

        div > *::-webkit-scrollbar {
            width: 12px;
        }

        div > *::-webkit-scrollbar-track {
            box-shadow: inset 0 0 10px 10px #e5e5e5;
            border: solid 4px transparent;
        }

        div > *::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 10px 10px #a3a3a3;
            border: solid 4px transparent;
            border-radius: 40px;
        }

        div > *::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px #737373;
        }
    }

    /* Workaround for <Select> component causing scrollbar re-render: https://github.com/shadcn-ui/ui/issues/4227 */
    html body[data-scroll-locked] {
        overflow: visible !important;
        margin-right: 0 !important;
    }
}
